// import Animjsone from "./animjsone";
import Animjstwoworld from "./animjstwoworld";
import "./App.css";
import Navbar from "./Navbar";

function App() {


  return (
  <>
  {/* <Animjsone /> */}
  <Navbar />
 
  <Animjstwoworld />
  </>
  );
}

export default App;
